exports.components = {
  "component---node-modules-gatsby-theme-abstract-blog-src-index-js": () => import("./../../../node_modules/gatsby-theme-abstract-blog/src/index.js" /* webpackChunkName: "component---node-modules-gatsby-theme-abstract-blog-src-index-js" */),
  "component---node-modules-gatsby-theme-abstract-blog-src-post-js": () => import("./../../../node_modules/gatsby-theme-abstract-blog/src/post.js" /* webpackChunkName: "component---node-modules-gatsby-theme-abstract-blog-src-post-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-slug-tsx": () => import("./../../../src/pages/{Page.slug}.tsx" /* webpackChunkName: "component---src-pages-page-slug-tsx" */),
  "component---src-pages-review-tsx": () => import("./../../../src/pages/review.tsx" /* webpackChunkName: "component---src-pages-review-tsx" */),
  "component---src-pages-service-list-tsx": () => import("./../../../src/pages/service-list.tsx" /* webpackChunkName: "component---src-pages-service-list-tsx" */)
}

